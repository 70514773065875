export const emailHTML = (emailData) => `
<!DOCTYPE html>
<html lang="nl">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Bevestiging Registratie Repeat Connect</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333;
    }
    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
    }
    h1 {
      color: #4CAF50;
    }
    .details {
      margin: 20px 0;
    }
    .button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      text-decoration: none;
      border-radius: 5px;
    }
    .footer {
      margin-top: 20px;
      font-size: 12px;
      color: #666;
    }
  </style>
</head>
<body>
    <div class="container">
        <h1>Gefeliciteerd!</h1>
        <p>Beste ${emailData?.name},</p>
        <p><i><b>Welkom bij Repeat Connect!</b></i></p>
        <p>Uw registratie bij Repeat Connect is succesvol voltooid. Binnen 24 uur krijgt u toegang tot ons digitale platform.</p>
        <p>Ons platform is uitsluitend toegankelijk via een computer, laptop of iPad. <span style="color:red"><b> Niet beschikbaar op een mobiele telefoon!</b></span></p>
        
        <h4>Uw inloggegevens:</h4>
        <div class="details">
            <p><strong>Gebruikersnaam:</strong> ${emailData?.gebruikersnaam}</p>
            <p><strong>Wachtwoord:</strong> ${emailData?.wachtwoord}</p>
        </div>
        
        <p>Klik op de onderstaande knop om in te loggen:</p>
        <p><a href="${emailData.inloglink}" class="button">Inloggen</a></p>
        
        <div class="footer">
            <p>Dit is een automatisch gegenereerde e-mail. Gelieve niet te reageren op dit bericht.</p>
        </div>
    </div>
</body>
</html>
`;

export const emailHTMLRegister = (emailData) => `
<!DOCTYPE html>
<html lang="nl">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Bevestiging Registratie Repeat Connect</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333;
    }
    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
    }
    h1 {
      color: #4CAF50;
    }
    .details {
      margin: 20px 0;
    }
    .button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      text-decoration: none;
      border-radius: 5px;
    }
    .footer {
      margin-top: 20px;
      font-size: 12px;
      color: #666;
    }
  </style>
</head>
<body>
    <div class="container">
        <h1>Gefeliciteerd!</h1>
        <p>Beste ${emailData?.name},</p>
        <p><i><b>Welkom bij Repeat Connect!</b></i></p>
        <p>Uw registratie bij Repeat Connect is succesvol voltooid. Binnen 24 uur krijgt u toegang tot ons digitale platform.</p>
        <p>Ons platform is uitsluitend toegankelijk via een computer, laptop of iPad. <span style="color:red"><b> Niet beschikbaar op een mobiele telefoon!</b></span></p>
        
        <h4>Uw inloggegevens:</h4>
        <div class="details">
            <p><strong>Gebruikersnaam:</strong> ${emailData?.gebruikersnaam}</p>
            <p><strong>Wachtwoord:</strong> ${emailData?.wachtwoord}</p>
        </div>

        <p>Vergeet niet uw profielgegevens aan te vullen. Klik hiervoor rechtsboven op uw profielfoto en selecteer vervolgens <b>"Profiel"</b>.</p>
        
        <p>Klik op de onderstaande knop om in te loggen:</p>
        <p><a href="${emailData.inloglink}" class="button">Inloggen</a></p>
        
        <div class="footer">
            <p>Dit is een automatisch gegenereerde e-mail. Gelieve niet te reageren op dit bericht.</p>
        </div>
    </div>
</body>

</html>
`;

export const emailToegangHTML = (emailData) => `
  <!DOCTYPE html>
  <html lang="nl">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Bevestiging Registratie Repeat Connect</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        color: #333;
      }
      .container {
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #f9f9f9;
      }
      h1 {
        color: #4CAF50;
      }
      .details {
        margin: 20px 0;
      }
      .button {
        display: inline-block;
        padding: 10px 20px;
        background-color: #4CAF50;
        color: white;
        text-decoration: none;
        border-radius: 5px;
      }
      .footer {
        margin-top: 20px;
        font-size: 12px;
        color: #666;
      }
    </style>
  </head>
  <body>
    <div class="container">
        <h1>Repeat Connect</h1>
        <p>Beste ${emailData?.name},</p>
        <p><i><b>Welkom aan boord!</b></i></p>
        <p>U heeft nu toegang tot ons digitale platform. Log in met de onderstaande gegevens:</p>
        
        <h4>Uw inloggegevens:</h4>
        <div class="details">
            <p><strong>Gebruikersnaam:</strong> ${emailData?.gebruikersnaam}</p>
            <p><strong>Wachtwoord:</strong> ${emailData?.wachtwoord}</p>
        </div>
        
        <p>Klik op de onderstaande knop om in te loggen:</p>
        <p><a href="${emailData.inloglink}" class="button">Inloggen</a></p>

        <h3>Belangrijk</h3>
        <p>Ons platform is uitsluitend toegankelijk via een computer, laptop of iPad. <span style="color:red"><b> Niet beschikbaar op een mobiele telefoon!</b></span></p>
        
        <div class="footer">
            <p>Dit is een automatisch gegenereerde e-mail. Gelieve niet te reageren op dit bericht.</p>
        </div>
    </div>
</body>

  </html>
  `;

export const emailToegangBeeindigdHTML = (emailData) => `
    <!DOCTYPE html>
    <html lang="nl">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Bevestiging Registratie Repeat Connect</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
        }
        .container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
          background-color: #f9f9f9;
        }
        h1 {
          color: #4CAF50;
        }
        .details {
          margin: 20px 0;
        }
        .button {
          display: inline-block;
          padding: 10px 20px;
          background-color: #4CAF50;
          color: white;
          text-decoration: none;
          border-radius: 5px;
        }
        .footer {
          margin-top: 20px;
          font-size: 12px;
          color: #666;
        }
      </style>
    </head>
    <body>
    <div class="container">
        <h1>Repeat Connect</h1>
        <p>Beste ${emailData?.name},</p>
        <p><i><b>Belangrijke mededeling</b></i></p>
        <p>Wij willen u informeren dat uw toegang tot ons digitale platform is beëindigd. Vanaf heden kunt u niet meer inloggen.</p>
        
        <h3>Wat betekent dit?</h3>
        <p>Uw account is gedeactiveerd en de bijbehorende inloggegevens zijn niet langer geldig.</p>
        
        <h3>Vragen?</h3>
        <p>Indien u denkt dat dit een vergissing is of als u verdere vragen heeft, neem dan contact op met onze supportafdeling.</p>
        
        <div class="footer">
            <p>Dit is een automatisch gegenereerde e-mail. Gelieve niet te reageren op dit bericht.</p>
        </div>
    </div>
</body>
  
    </html>
    `;

export const emailPreintakeBevestigingHTML = (emailData) => `
    <!DOCTYPE html>
    <html lang="nl">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Bevestiging Pre-intake Formulier</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
        }
        .container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
          background-color: #f9f9f9;
        }
        h1 {
          color: #4CAF50;
        }
        .details {
          margin: 20px 0;
        }
        .footer {
          margin-top: 20px;
          font-size: 12px;
          color: #666;
        }
      </style>
    </head>
    <body>
    <div class="container">
        <h1>Repeat Connect</h1>
        <p>Beste ${emailData?.name},</p>
        <p><i><b>Bevestiging Pre-intake Formulier</b></i></p>
        <p>Wij hebben uw pre-intake formulier succesvol ontvangen. Bedankt voor het invullen!</p>
        
        <h3>Wat gebeurt er nu?</h3>
        <p>Ons team zal uw formulier zorgvuldig beoordelen. Indien nodig nemen we contact met u op voor verdere vragen of aanvullende informatie.</p>

        <p>Gelieve verder te gaan met het invullen van het <b>Klachtenregister formulier</b></p>
        
        <h3>Vragen?</h3>
        <p>Heeft u vragen over uw inzending? Neem gerust contact op met onze supportafdeling.</p>
        
        <div class="footer">
            <p>Dit is een automatisch gegenereerde e-mail. Gelieve niet te reageren op dit bericht.</p>
        </div>
    </div>
    </body>
    </html>
    `;

export const emailKlachtenBevestigingHTML = (emailData) => `
    <!DOCTYPE html>
    <html lang="nl">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Bevestiging Pre-intake Formulier</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
        }
        .container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
          background-color: #f9f9f9;
        }
        h1 {
          color: #4CAF50;
        }
        .details {
          margin: 20px 0;
        }
        .footer {
          margin-top: 20px;
          font-size: 12px;
          color: #666;
        }
      </style>
    </head>
    <body>
    <div class="container">
        <h1>Repeat Connect</h1>
        <p>Beste ${emailData?.name},</p>
        <p><i><b>Bevestiging Klachtenregister Formulier</b></i></p>
        <p>Wij hebben uw klachtenregister formulier succesvol ontvangen. Bedankt voor het invullen!</p>
        
        <h3>Wat gebeurt er nu?</h3>
        <p>OMet het klachtenregisterformulier ingevuld,  blijft er nog één in te vullen lijst over: het voedingsdagboek. 
        <br/> Wanneer deze ingevuld is, wacht het intakegesprek af. 
        <br/> Tijdens het intakegesprek neemt jouw therapeut de lijsten zorgvuldig met jou door om een duidelijk beeld te scheppen
        <br/> over jouw gezondheid en hulpvraag.</p>

        <p>Gelieve verder te gaan met het invullen van het <b>voedingsdagboek</b></p>
        
        <h3>Vragen?</h3>
        <p>Heeft u vragen over uw inzending? Neem gerust contact op met onze supportafdeling.</p>
        
        <div class="footer">
            <p>Dit is een automatisch gegenereerde e-mail. Gelieve niet te reageren op dit bericht.</p>
        </div>
    </div>
    </body>
    </html>
    `;

export const emailVoedingHTML = (emailData) => `
    <!DOCTYPE html>
    <html lang="nl">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Bevestiging Pre-intake Formulier</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
        }
        .container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
          background-color: #f9f9f9;
        }
        h1 {
          color: #4CAF50;
        }
        .details {
          margin: 20px 0;
        }
        .footer {
          margin-top: 20px;
          font-size: 12px;
          color: #666;
        }
      </style>
    </head>
    <body>
    <div class="container">
    <h1>Repeat Connect</h1>
    <p>Beste ${emailData?.name},</p>
    <p><i><b>Bevestiging voedingsdagboek Formulier</b></i></p>
    <p>Wij hebben uw voedingsdagboek succesvol ontvangen. Bedankt voor het invullen!</p>
    
    <h3>Wat gebeurt er nu?</h3>
    <p>Ons team zal uw formulier zorgvuldig beoordelen. Indien nodig nemen we contact met u op voor verdere vragen of aanvullende informatie.</p>

    <p>Daarnaast kunt u dagelijks uw voedingsdagboek invullen via de sectie "Mijn Dagboek", die u kunt vinden in het rechter navigatiemenu.</p>
    
    <h3>Vragen?</h3>
    <p>Heeft u vragen over uw inzending? Neem gerust contact op met onze supportafdeling.</p>
    
    <div class="footer">
        <p>Dit is een automatisch gegenereerde e-mail. Gelieve niet te reageren op dit bericht.</p>
    </div>
</div>


    </body>
    </html>
    `;