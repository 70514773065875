import React, { useEffect, useLayoutEffect, useState } from "react";
import Calendar from "../../Componenten/Calender/Calendar";
import BannerPlannig from "../../Layout/BannerPlannig";
import MonthView from "../../Componenten/Calender/MonthView";
import WeekView from "../../Componenten/Calender/WeekView";
import DayView from "../../Componenten/Calender/DayView";
import UpcomingAgenda from "../../Componenten/Calender/UpcomingAgenda";
import DoubleMeeting from "../../Componenten/Calender/DoubleMeeting";
import {
  setAppointment,
  setAppointmentLoad,
  setAppointmentLoadInWacht,
} from "../../redux/appointmentSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  AXIOS_GET,
  AXIOS_GET_CAL_SCHEDULED_EVENTS,
  AXIOS_GET_LIST_USER_AVAIBLES,
} from "../../server/api/crud";
import {
  getAdmins,
  getUser,
  getUserdetails,
} from "../../Localstorage/localstorage";
import PatientenView from "../../Componenten/Calender/PatientenView";
import {
  filterEventsByAdminsEmail,
  filterEventsByUserEmail,
} from "../../function";
import { MutatingDots } from "react-loader-spinner";
import { SpinnersMutatingDots } from "../../Componenten/Calender/Spinners";
import moment from "moment";

const Items = [
  { id: 0, name: "Maand", viewComponent: MonthView },
  { id: 1, name: "Week", viewComponent: WeekView },
  { id: 2, name: "Dag", viewComponent: DayView },
  { id: 3, name: "Geplande Afspraken", viewComponent: UpcomingAgenda },
  { id: 4, name: "Patienten", viewComponent: PatientenView },
  { id: 5, name: "Overzicht 2 maanden", viewComponent: DoubleMeeting },
];

const Planning = () => {
  const user = getUser();
  const userdetails = getUserdetails();
  const admins = getAdmins();
  const gebruiker = userdetails?.role_id == 2 ? admins[0] : userdetails;
  const [isLoading, setIsLoading] = useState(false);

  const [view, setView] = useState(0);
  const dispatch = useDispatch();
  const [select, setSelect] = useState(gebruiker);

  const { appointments } = useSelector((state) => state.appointment);

  //const { user } = useSelector((state) => state.appointment);

  const getAgenda = async (id) => {
    try {
      setIsLoading(true);
      const email = user?.username.includes('@') ? user?.username : (user?.email ? user?.email : userdetails?.email);
      const paramUser = {
        organization:
          "https://api.calendly.com/organizations/e5ba7299-d262-4435-acc9-67a68893e28f",
          count:'100',
        sort: "start_time:asc",
        min_start_time:moment().startOf('month'),
        invitee_email:email
      };

      const responses = await AXIOS_GET_CAL_SCHEDULED_EVENTS(paramUser);
      let collection = responses?.collection;
      let arra = filterEventsByUserEmail(collection, userdetails?.email);
      dispatch(setAppointmentLoad(arra));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching afspraak", error);
    }
  };

  const getAgendaAmdin = async () => {
    try {
      setIsLoading(true);
      const paramAdmin = {
        organization:
          "https://api.calendly.com/organizations/e5ba7299-d262-4435-acc9-67a68893e28f",
          count:'100',
        sort: "start_time:asc",
        status: "active",

        user: userdetails?.calendly_info?.uri
      };

      const responses = await AXIOS_GET_CAL_SCHEDULED_EVENTS(paramAdmin);
      let collection = responses?.collection;
      let arra = filterEventsByAdminsEmail(collection, select.email);
      dispatch(setAppointmentLoad(arra));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching afspraak", error);
    }
  };

  useEffect(() => {
    if (user.role == 1) {
      if (select?.user_id) {
        getAgendaAmdin();
      }
    } else {
      getAgenda();
    }
  }, [select]);

  useLayoutEffect(() => {
    // Example: Fetch mailrooms when the component mounts

    if (user.role == 2) {
      getAgenda();
    } else {
      // Example: Fetch mailrooms when the component mounts
      getAdmins();
    }
  }, []);

  return (
    <div className="h-full dark:bg-gray-800 gap-y-2 h-full gap-y-2">
      <BannerPlannig
        bannerName={"Planning"}
        Changeview={setView}
        selected={select}
        setSelected={setSelect}
        index={view}
        views={Items}
      />
      <Calendar
        select={user.role == 2 ? userdetails : select}
        user={user}
        userdetails={userdetails}
        appointments={appointments}
        Component={Items[view]}
      />
      {isLoading && <SpinnersMutatingDots/>}
    </div>
  );
};

export default Planning;
