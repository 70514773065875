import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  benamingKlachten,
  radiobutton1,
  KlachtenTitel,
} from "../../klantenregister";
import RPRadioButtonSmall from "../../Componenten/Radiogroup/RadioButtonSmall";
import { AXIOS_POST, AXIOS_UPDATE } from "../../server/api/crud";
import Swal from "sweetalert2";
import {
  ArrowDownIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/20/solid";
import Slider from "react-slick/lib/slider";
import {
  getKlacht,
  getStates,
  getUserdetails,
  setStates,
} from "../../Localstorage/localstorage";
import {
  opslaanKlachten,
  opslaanOpmerkingKlachten,
  versturenKlachten,
} from "../../apiCalls/klachtenformulierApi";
import { emailKlachtenBevestigingHTML } from "../../EmailTemplates";
import { sendEmail } from "../../function";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Bedankt = ({ handleClose, versturen }) => (
  <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg mx-auto">
      <h1 className="text-3xl font-bold text-green-600 mb-4 text-center">
        Bedankt voor het invullen!
      </h1>
      <p className="text-gray-700 mb-4 text-center">
        We waarderen uw tijd en zorgvuldigheid bij het invullen van het
        formulier.
      </p>
      <p className="text-gray-700 mb-4 text-center">
        Uw gegevens zijn veilig ontvangen en worden momenteel zorgvuldig door
        ons team beoordeeld.
      </p>
      <p className="text-gray-700 mb-4 text-center">
        Binnenkort nemen wij contact met u op om een intakegesprek te plannen.
      </p>
      <p className="text-gray-700 mb-4 text-center">
        Indien u nog vragen heeft, kunt u altijd contact met ons opnemen.
      </p>
      <p className="text-gray-700 mb-4 text-center">
        Wij staan klaar om u te ondersteunen en wensen u alvast een prettige dag
        toe.
      </p>
      <p className="text-gray-700 mb-6 text-center">
        Hartelijk dank voor uw vertrouwen in onze zorg!
      </p>
      <button
        onClick={handleClose}
        className="w-full bg-green-600 hover:bg-green-700 text-white py-3 rounded-lg focus:outline-none transition duration-300 ease-in-out"
      >
        Sluiten
      </button>

      <button
        onClick={versturen}
        className="mt-4 w-full bg-green-800 hover:bg-green-700 text-white py-3 rounded-lg focus:outline-none transition duration-300 ease-in-out"
      >
        Verzenden
      </button>
    </div>
  </div>
);

const RpKlachtenRegisterForm = ({
  klachten,
  setKlachten,
  klacht,
  status,
  setOpen,
  admin = null,
}) => {
  const topRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [extra, setExtra] = useState(0);
  const userdetails = getUserdetails();

  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (current, next) => setSlideIndex(next),
  };

  const [opAdminOpmerking, setopAdminOpmerking] = useState(null);
  const [Opmerking, setOpmerking] = useState(null);
  const [categorie, setCategorie] = useState(klachten);
  const startAdmin = admin ? 0 : status?.id;
  const [selectKlacht, setSelectKlacht] = useState(startAdmin);
  const vragenlijst = getKlacht();

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    if (admin && name === 'opm_admin') {
      setopAdminOpmerking(value);
    }

    setKlachten((prevState) => ({
      ...prevState,
      [benamingKlachten[selectKlacht]?.key]: {
        ...prevState[benamingKlachten[selectKlacht]?.key],
        [name]: value,
      },
    }));
  }, [admin, selectKlacht, benamingKlachten]);

  useEffect(() => {
    let newselect = getStates();
    const startAdmin = (admin || vragenlijst?.versturen == 1) ? 0 : newselect?.id;
    setSelectKlacht(startAdmin);
  }, [status, setStates]);

  useEffect(() => {
    if (benamingKlachten[selectKlacht]) {
      setopAdminOpmerking(
        klachten[benamingKlachten[selectKlacht]?.key]?.opm_admin
      );
      if(admin){
        setOpmerking(klachten[benamingKlachten[selectKlacht]?.key]?.opm_admin);
      }
    }
    let categorie = klachten[benamingKlachten[selectKlacht]?.key];
    console.log("categorie ==> ",categorie);
    console.log("selectKlacht ==> ",selectKlacht);

  }, [selectKlacht]);

  const verSturen = async () => {
    await versturenKlachten(klacht, setOpen);
    const emailData = {
      from_name: "RepeatConnect Klachtenregister formulier Verzonden",
      sender_email: "info@repeathealth.com", // Replace with dynamic sender email
      title: `Hallo ${userdetails?.first_name}, Uw ingevulde klachtenregisterformulier is succesvol verzonden.`,
      receiver_email: userdetails?.email, // Replace with dynamic receiver email
      gebruikersnaam: userdetails?.email, // Replace with dynamic userdetailsname
      name: userdetails?.full_name,
      inloglink: "https://repeatconnect.com", // Replace with the login URL
    };

    const emailHtml = emailKlachtenBevestigingHTML(emailData);
    sendEmail("template_qdoh9mv", {
      message_html: emailHtml,
      title: emailData?.title,
      receiver_email: emailData?.receiver_email,
    });
  };

  const Opslaan = async () => {
    let categorie = klachten[benamingKlachten[selectKlacht]?.key];
    console.log("categorie ==> ",categorie);
    console.log("selectKlacht ==> ",selectKlacht);

    if (admin == null) {
      await opslaanKlachten(
        klacht,
        selectKlacht,
        setStates,
        categorie,
        setKlachten,
        setExtra
      );
    } else {
      await opslaanOpmerkingKlachten(
        klacht,
        selectKlacht,
        setStates,
        categorie,
        admin,
        setOpen
      );
    }
    setSelectKlacht((prev) => prev + 1);
  };

  const updateKlachten = (categoryKey, itemKey, newValue) => {
    setKlachten((prevState) => ({
      ...prevState,
      [categoryKey]: {
        ...prevState[categoryKey],
        [itemKey]: newValue,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (admin) {
      if (Opmerking == opAdminOpmerking) {
        if(benamingKlachten[selectKlacht]?.title == 'Man'){
          setOpen(false)
        }else{
          setSelectKlacht((prev) => prev + 1);
        }
      } else {
        Opslaan();
      }
    } else {
      Opslaan();
    }

    // Handle form submission logic here
    // Example: Send data to backend or perform state update
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    setSelectKlacht((prev) => prev - 1);
    // Example: Send data to backend or perform state update
  };

  return (
    <div ref={topRef} className="max-w-8xl mx-auto mt-8">
      {extra == 1 ? (
        <Bedankt handleClose={() => setOpen(false)} versturen={verSturen} />
      ) : (
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          <div>
            <div className="flex justify-center">
              <div className="w-3/4">
                <h2 className="text-xl font-semibold leading-6 text-gray-900">
                  Klachtenregister formulier "Van kop tot teen"
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Met meer dan 1 miljard biochemische reacties per seconde, is
                  ons lichaam eens van de meest complexe levende systemen op
                  deze aarde. Door deze vragenlijst help je ons jouw lichaam
                  beter te begrijpen.
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  Noteer achter de vraag op een schaal van 0 tot 3 punten hoe
                  vaak u last hebt van de klachten en/of hoe belastend u ze
                  ervaart.{" "}
                </p>
                <ul
                  role="list"
                  className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2"
                >
                  {radiobutton1?.map((option) => (
                    <li key={option.id} className="flow-root">
                      <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                        <div
                          className={classNames(
                            "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg",
                            "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          )}
                        >
                          {option.id}
                        </div>
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">
                            <a href="#" className="focus:outline-none">
                              <span
                                aria-hidden="true"
                                className="absolute inset-0"
                              />
                              <span>{option?.title}</span>
                              <span aria-hidden="true"> &rarr;</span>
                            </a>
                          </h3>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="mt-4 flex">
                  <button
                    onClick={(e) => sliderRef?.slickGoTo(1)}
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Beginnen met het invullen
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-2xl mb-4 flex  items-center gap-x-2">
              {benamingKlachten[selectKlacht]?.title} <span className="text-red-600 text-sm">(Vergeet niet naar beneden te scrollen) </span>             <ArrowDownIcon className="h-4 w-4"/>

            </h2>
            
            <div>
            <div className="grid grid-cols-2 overflow-y-scroll px-2"               
            style={{ maxHeight: 370 }}
            >
              {klachten[benamingKlachten[selectKlacht]?.key] &&
                extra == 0 &&
                Object.entries(klachten[benamingKlachten[selectKlacht]?.key])
                  .filter(
                    ([key, value]) =>
                      key !== "opm_klant" &&
                      key !== "opm_admin" &&
                      key !== "id" &&
                      key != "admin_id" &&
                      key !== "klachtr_id"
                  )
                  .map(([key, value]) => (
                    <RPRadioButtonSmall
                      key={key}
                      legend={
                        KlachtenTitel[benamingKlachten[selectKlacht]?.key][key]
                      }
                      klachten={klachten}
                      keyHoofd={key}
                      categorie={benamingKlachten[selectKlacht]?.key}
                      updateKlachten={updateKlachten}
                      valueRadio={value}
                      Value={value}
                      admin={admin}
                    />
                  ))}
            </div>
            </div>

            <div className="mt-2">
              <label
                htmlFor="opm_klant"
                className="block text-sm font-medium text-gray-700"
              >
                Wenst u nog andere dingen aan te vullen?
              </label>
              <textarea
                id="opm_klant"
                name="opm_klant"
                disabled={admin != null}
                value={klachten[benamingKlachten[selectKlacht]?.key]?.opm_klant|| ""}
                onChange={handleChange}
                rows="5"
                className="mt-1 p-4 ring-1 ring-inset ring-gray-300 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {admin != null && (
              <div className="mt-8">
                <label
                  htmlFor="opm_admin"
                  className="block text-sm font-medium text-gray-700"
                >
                  Opmerking Specialist
                </label>
                <textarea
                  id="opm_admin"
                  name="opm_admin"
                  value={klachten[benamingKlachten[selectKlacht]?.key]?.opm_admin || ""}
                  onChange={handleChange}
                  rows="5"
                  className="mt-1 p-4 ring-1 ring-inset ring-gray-300 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            )}
            <div className="mt-4">
              {selectKlacht > 0 && (
                <button
                  onClick={handlePrevious}
                  className="inline-flex items-center mr-2 gap-x-1.5 rounded-md bg-green-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <ArrowLeftCircleIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5"
                  />
                  Vorige
                </button>
              )}
              <button
                onClick={handleSubmit}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <ArrowRightCircleIcon
                  aria-hidden="true"
                  className="-ml-0.5 h-5 w-5"
                />
                Volgende
              </button>
            </div>
          </div>
          <div></div>
        </Slider>
      )}
    </div>
  );
};

export default RpKlachtenRegisterForm;
