import { createSlice } from '@reduxjs/toolkit';
import { getAdmins, getUser, getUserdetails } from '../Localstorage/localstorage';

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: {
    appointments: [],
    appointmentsCalendly:[],
    command: '',
    user: getUser(),
    userdetails: getUserdetails(),
    users:[],
    admins: getAdmins()
  },
  reducers: {
    setAppointment: (state, action) => {
      // Appends the new item to the existing mailRooms array
      state.appointments = [...state.appointments, action.payload];
    },
    setAppointmentLoad: (state, action) => {
        state.appointments = action.payload;
    },
    setAppointmentsCalendly: (state, action) => {
      // Appends the new item to the existing mailRooms array
      state.appointmentsCalendly = [...state.appointmentsCalendly, action.payload];
    },
    setAppointmentsCalendlyLoad: (state, action) => {
        state.appointmentsCalendly = action.payload;
    },
    setAppointmentsCalendlyDelete: (state, action) => {
      console.log(state.appointmentsCalendly) 
      state.appointmentsCalendly = state.appointmentsCalendly.filter((_, index) => 
        index !== action.payload);
      console.log(state.appointmentsCalendly) 
    },
    setAppointmentUpdate: (state, action) => {
      state.appointments = state.appointments.map(appointment => 
        appointment.id === action.payload.id 
            ? { ...appointment, ...action.payload }  // Merge existing appointment with updatedComment
            : appointment // Leave other comments unchanged
    );},
    setAppointmentDelete: (state, action) => {
      state.appointments = state.appointments.filter(appointment => 
        appointment.id != action.payload.id);},
    setCommand: (state, action) => {
      // This one seems to replace command entirely, no change needed here
      state.command = action.payload;
    },
    setUsers: (state, action) => {
      // This one seems to replace user entirely, no change needed here
      state.users = action.payload;
    },
  },
  
});

export const {
  appointments,
  appointmentsCalendly,
  command,
  user,
  userdetails,
  users,
  admins,
  setAppointment,
  setAppointmentLoad,
  setAppointmentInWacht,
  setAppointmentLoadInWacht,
  setAppointmentsCalendly,
  setAppointmentsCalendlyLoad,
  setAppointmentUpdate,
  setAppointmentDelete,
  setAppointmentsCalendlyDelete,
  setCommand,
  setUsers
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
