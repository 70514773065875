import axios from 'axios';
import { BASE_URL, BASE_URL_API, BASE_URL_API_CAL, BASE_URL_API_CAL_EVENT_TYPES, BASE_URL_API_CAL_INVITEES, BASE_URL_API_CAL_SCHEDULED_EVENTS, BASE_URL_API_CAL_USERS, CALENDLY_API_KEY } from '../../constants/constants';
import { clearLocalStorage, getAccesToken, setAccesToken, signOutUser } from '../../Localstorage/localstorage';
import { eventUserColor } from '../../function';
import moment from 'moment';
const logged = false;
// Timezone for requests
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Build headers function to include the access token
const buildHeaders = () => {
  const accessToken = getAccesToken();  // Retrieve access token from storage
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;  // Add access token to Authorization header
  }
  return headers;
};

const buildHeadersCalendly = () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${CALENDLY_API_KEY}` // Store token securely
  };
  return {headers};
};

// Refresh Access Token Function
const refreshAccessToken = async () => {
  try {
    const response = await axios.post(`${BASE_URL_API}/refresh-token`, {}, { withCredentials: true });
    const { accessToken } = response.data;
    setAccesToken(accessToken); // Update access token in local storage
    return accessToken;
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    throw error;
  }
};

// Axios instance with interceptor for automatic token refresh
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;  // Mark the request to avoid infinite loop

      try {
        const newAccessToken = await refreshAccessToken();  // Refresh access token
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;  // Update header with new token
        logged = true; // Retry the original request

        return axiosInstance(originalRequest); 
      } catch (refreshError) {
        console.error("Refresh token failed. Redirecting to login.");
        if (logged == true) {
        // Handle redirect to login or logout the user
        clearLocalStorage();
        signOutUser()
        window.location.href = '/signin';   
        logged = false;     
      }

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// Axios requests setup
export const AXIOS_WITHOUT_ACCESSTOKEN = (p_url, data) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/klantenportaal/${p_url}`,
    data: data,
  });
};

export const AXIOS_GET_CAL = async (p_url, params = null) => {
  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.get(`${BASE_URL_API_CAL}/${p_url}`, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }
};

export const AXIOS_POST_CAL = async (p_url, data, params = null) => {

  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.post(`${BASE_URL_API_CAL}/${p_url}`, data, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }

};

export const AXIOS_GET_CAL_EVENTS = async (p_uid, params = null) => {
  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.get(`${BASE_URL_API_CAL_EVENT_TYPES}/${p_uid}`, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }
};

export const AXIOS_POST_CAL_EVENTS = async (p_url, data, params = null) => {
  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.post(`${BASE_URL_API_CAL_EVENT_TYPES}/${p_url}`, data, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }

};

export const AXIOS_GET_CAL_USERS = async (p_url, params = null) => {
  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.get(`${BASE_URL_API_CAL_USERS}/${p_url}`, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }
};

export const AXIOS_POST_CAL_USERS = async (p_url, data, params = null) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${CALENDLY_API_KEY}` // Store token securely
  };

  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.post(`${BASE_URL_API_CAL_USERS}/${p_url}`, data, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }

};

export const AXIOS_GET_CAL_SCHEDULED_EVENTS = async (params = null) => {
  const options = buildHeadersCalendly();

  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.get(`${BASE_URL_API_CAL_SCHEDULED_EVENTS}`, options);
    const data = response.data;

    delete options?.params?.sort;
    delete options?.params?.count;
    delete options?.params?.user;
    delete options?.params?.min_start_time;
    delete options?.params?.invitee_email;

    if (data?.collection) {
      // Prepare the promises for fetching invitees in parallel
      const inviteeFetchPromises = data.collection.map(async (item) => {
        
        const inviteesResponse = axios.get(`${item.uri}/invitees`, options);
        let newname= item?.name.trimEnd()
        
        return inviteesResponse.then(res => ({
          ...item,
          cols:moment(item.start_time).day() == 0
          ? "col-start-7"
          : `col-start-${moment(item.start_time).day()}`,
          creators: item.event_memberships,
          invitees: res.data?.collection,
          color:eventUserColor[newname]
        }));
      });

      // Wait for all invitee fetches to complete in parallel
      data.collection = await Promise.all(inviteeFetchPromises);
     
    }

    return data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }
};

export const AXIOS_GET_SCHEDULED_EVENTS = async (p_url, params = null) => {
  const headers = buildHeaders();

  return axiosInstance.get(`${BASE_URL_API}/${p_url}`, { headers });
};


export const AXIOS_POST_CAL_SCHEDULED_EVENTS = async ( data, params = null) => {

  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.post(`${BASE_URL_API_CAL_SCHEDULED_EVENTS}`, data, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }

};

export const AXIOS_GET_CAL_INVITEES = async (p_url, params = null) => {
  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.get(`${BASE_URL_API_CAL_INVITEES}/${p_url}`, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }
};

export const AXIOS_POST_CAL_INVITEES = async (p_url, data, params = null) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${CALENDLY_API_KEY}` // Store token securely
  };

  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.post(`${BASE_URL_API_CAL}/${p_url}`, data, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }

};

export const AXIOS_GET_LIST_USER_AVAIBLES = async (params=null) => {
  const options = buildHeadersCalendly();

   // Add params if they exist
   if (params) {
    options.params = params;
  }

  try {
    const response = await axios.get(`https://api.calendly.com/user_availability_schedules`, options);
    return response.data;
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error;
  }

} 

export const AXIOS_GET_EVENT_TYPES = async (params = {
  active: 'true',
  organization: 'https://api.calendly.com/organizations/e5ba7299-d262-4435-acc9-67a68893e28f',
  user: 'https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a'
}) => {
  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.get(`https://api.calendly.com/event_types`, options);
    return response.data; // Return the response data
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error; // Throw error for handling upstream
  }
};

export const AXIOS_GET_EVENT_TYPES_USERS = async (params = {
  active: 'true',
  organization: 'https://api.calendly.com/organizations/e5ba7299-d262-4435-acc9-67a68893e28f',
  user: 'https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a'
}) => {
  const options = buildHeadersCalendly();

  // Add params if they exist
  if (params) {
    options.params = params;
  }

  try {
    const response = await axios.get(`https://api.calendly.com/event_types`, options);
    return response.data; // Return the response data
  } catch (error) {
    console.error('Calendly API Error:', error);
    throw error; // Throw error for handling upstream
  }
};

export const AXIOS_GET = async (p_url) => {
  const headers = buildHeaders();
  return axiosInstance.get(`${BASE_URL_API}/${p_url}`, { headers });
};

export const AXIOS_POST = async (p_url, data) => {
  const headers = buildHeaders();
  return axiosInstance.post(`${BASE_URL_API}/${p_url}`, data, { headers });
};

export const AXIOS_DELETE = async (p_url, id, data = {}) => {
  const headers = buildHeaders();
  return axiosInstance.delete(`${BASE_URL_API}/${p_url}/${id}`, {
    headers,
    data: { ...data, timeZone: timezone },
  });
};

export const AXIOS_UPDATE = async (p_url, id, data) => {
  const headers = buildHeaders();
  return axiosInstance.patch(`${BASE_URL_API}/${p_url}/${id}`, { ...data, timeZone: timezone }, { headers });
};

export const AXIOS_UPDATE_MORE = async (p_url, ids, data) => {
  const headers = buildHeaders();
  const idsString = Array.isArray(ids) ? ids.join(',') : ids;
  return axiosInstance.patch(`${BASE_URL_API}/${p_url}/${idsString}`, { ...data, timeZone: timezone }, { headers });
};

export const AXIOS_UPLOAD_IMAGE = async (p_url, id, imageFile) => {
  const headers = buildHeaders();
  const formData = new FormData();
  formData.append('avatar', imageFile);
  return axiosInstance.post(`${BASE_URL_API}/${p_url}/upload/${id}`, formData, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const AXIOS_UPLOAD_DOCUMENTS = async (p_url, formData) => {
  const headers = buildHeaders();
  return axiosInstance.post(`${BASE_URL_API}/${p_url}`, formData, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const AXIOS_UPLOAD_DOCUMENT = async (p_url, formData) => {
  const headers = buildHeaders();
  return axiosInstance.post(`${BASE_URL_API}/${p_url}`, formData, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};
